import lightGallery from 'lightgallery';
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import fullScreen from 'lightgallery/plugins/fullscreen'

export default {
  init() {
    // JavaScript to be fired on all pages
    
  },
  finalize() {
    lightGallery(document.getElementById('project-gallery'), {
      licenseKey: 'license_key_here',
      selector: '.gallery-image',
      plugins: [lgZoom, lgThumbnail, fullScreen],
      speed: 500,
      fullScreen: true,
      mobileSettings: {
        controls: true,
        showCloseIcon: true,
        download: false,
      },
    });
  },
};
